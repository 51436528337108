.header
{
    position: fixed;
    width: 100%;
    height: 108px;
    left: 0px;
    top: 0px;
    background: #212123;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
@media only screen and (max-width: 1100px) {
    .logo
    {
        height: 54px;
    }
    .header
    {
        height: 80px;
    }
}