.btn
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 32px;
    gap: 8px;
    height: 42px;
    border: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border-radius: 40px;
    justify-content: center;
}

.btn_large
{
    padding: 22px 48px;
    gap: 12px;
    height: 68px;
    font-size: 20px;
}

.btn_small
{
    padding: 12px 15px;
    gap: 8px;
    height: 40px;
    font-size: 20px;
}

.btn_round
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 40px;
    border: 0;
    padding: 0 0;
    gap:0 !important;
}
.btn_round_large
{
    height: 74px;
    width: 74px;
    border-radius: 40px;
    padding: 0 0;
}
.btn_round_small
{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 0 0;
}

.primary_back
{
    background: #E0A923;
}
.primary_color
{
    color: black;
}

.secondary_back
{
    background: black;
    backdrop-filter: blur(27px);
}
.secondary_color
{
    color: white;
    border: 2px solid white;
}

.default_back
{
    background-color: #ffffff;
    box-shadow: 1px 1px 9px #cecece;
}
.white_back
{
    background: white;
    border: 1px solid #ADADAD;
}
.default_color
{
    color: #000000;
}

.btn:disabled
{
    background: #eaeaea;
    color: #444444;
    
}


@media only screen and (max-width: 800px) {

    .btn_large
    {
        padding: 12px 35px;
        gap: 12px;
        height: 58px;
        font-size: 20px;
        font-size: 18px;
    }
    .btn_round_large
    {
        height: 44px;
        width: 44px;
        border-radius: 40px;
        padding: 0 0;
    }
}
