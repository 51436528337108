

.shadebox_text
{
    border-bottom:1px solid #CCCCCC;
    width: 421px;
    text-align: right;
    font-weight: 600;
    font-size: 20px;
    line-height: 46px;
    letter-spacing: 0.02em;
    min-height: 46px;
    padding-right: 12px;
}
.shadebox
{
    background-color: white;
    border:1px solid #CCCCCC;
    border-top: 0;
    flex: 1;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shadenumber
{
    background-color: #EAEAEA;
    width: 89.2px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin-right: 1px;
} 
.shade1
{
    background-color: #EE6C4B;
}
.shade2
{
    background-color: #F4A337;
}
.shade3
{
    background-color: #F9D924;
}
.shade4
{
    background-color: #AAC848;
}
.shade5
{
    background-color: #59B66D;
}
 
.schedule_container
{
    display: flex;
    gap: 32px;
    max-width: 900px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.schedule_btn
{
    width:fit-content
}
@media only screen and (max-width: 600px) {

    .schedule_container
    {
        flex-direction: column;
    }
    .shadebox_text
    {
        font-size: 13px;
        line-height: 16px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 6px;
    }
    .graph_title
    {
        font-size: 12px;
    }
    
}
@media only screen and (max-width: 600px) {
    .schedule_btn
    {
        width: 100%;
    }
}
