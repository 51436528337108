
.progressbar
{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: white;
}
.progress
{
    border-radius: 2px;
    background-color: #555555;
    height: 14px;
    flex: 1;
    overflow: hidden;
}
.progress_in
{
    background-color: #555555;
    background: #FFC026;
    height: 14px;
}
.progress_in_success
{
    background: linear-gradient(92.48deg, #4EEA9F 1.45%, #01A880 123.93%);   
}