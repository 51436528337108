.checkmark
{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #000;
  display: block;
  span
  {
    height: 17px;
    width: 17px;
    margin: 3px;
    background-color: white;
    display: block;
    border-radius: 50%;
  }
}
.checkmarkchecked
{
  span
  {
    background-color: #000;
  }
}
.optionblock
{
    display:flex;
    align-items:center;
    flex-direction:column;
    gap:10px;
    cursor:pointer;
    width: 117px;
}
.questiontext
{
  font-size:24px;
  font-weight:500;
}
.optioncontainer
{
  padding-left:30px;
  margin-top:25px;
  max-width:1000;
  display:flex;
  gap:20px;
  flex-wrap:wrap;
}
@media only screen and (max-width: 1100px) {
  .questiontext
  {
    font-size:20px;
  } 
  .optioncontainer
  {
    display: block;
    margin-top:15px;
  }
  .optionblock
  {
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
  }
}