.hero
{
    min-height: 350px;
    width: 100%;
    background: #000000;
    background-image: url('./hero.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
@media only screen and (max-width: 1100px) {
    .hero
    {
        min-height: 120px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}